import React from 'react';
import Layout from '../components/layout';

const browser = typeof window !== 'undefined' && window;

const NotFoundPage = () =>
  browser && (
    <Layout>
      <section className="container">
        <h1 className="is-size-1 mt-10">NOT FOUND</h1>
      </section>
    </Layout>
  );

export default NotFoundPage;
